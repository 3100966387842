import React from 'react'

const Loading = () => {
  return (
    <>
      <div id="preloader"></div>
    </>
  )
}

export default Loading